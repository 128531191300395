import * as React from "react";

import {
    useParams
  } from "react-router";
import Web3 from 'web3';
import ABI from './dao.json'
import tokenabi from './erc20.json'
import {
  injected,
  walletconnect,
} from "./connectors";
import walletconnecticon from './walletconnect.png';
import useGetPriceDataCustom from './getPrice'
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import { useEagerConnect, useInactiveListener } from "./hooks";
import Logo from './gcmelogo.png'
import Metamask from './metamask.png'
import socials from './socials.png'
import Wconnect from './walletconnect.png'
import circle from './circle.png'
import multicallabi from './multicall.json';
import { Interface } from '@ethersproject/abi'
import { ethers } from 'ethers'
import orgtoken from './orgtoken.png'
import reddit from './reddit.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import medium from './medium.png'

import "./Range.css"
const axios = require('axios').default;


function Analytics() {

  const connectorsByName = {
    Injected: injected,
    WalletConnect: walletconnect
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  


 
let { address } = useParams();
const context = useWeb3React();
const {
  connector,
  library,
  chainId,
  account,
  activate,
  deactivate,
  active,
  error
} = context;


    const [activatingConnector, setActivatingConnector] = React.useState();
    React.useEffect(() => {
      console.log('running')
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  // set up block listener
  const [blockNumber, setBlockNumber] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (library) {
      let stale = false;

      console.log('fetching block number!!')
      library
        .getBlockNumber()
        .then(blockNumber => {
          if (!stale) {
            setBlockNumber(blockNumber);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(null);
          }
        });

      const updateBlockNumber = blockNumber => {
        setBlockNumber(blockNumber);
      };
      library.on("block", updateBlockNumber);

      return () => {
        library.removeListener("block", updateBlockNumber);
        stale = true;
        setBlockNumber(undefined);
      };
    }
  }, [library, chainId]);

  // fetch eth balance of the connected account
  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


    
    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [bannerUrl, setBannerUrl] = React.useState('')
    const [avatar, setAvatar] = React.useState('')
    const [name, setName] = React.useState('')
    const [location, setLocation] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [softcap, setSoftcap] = React.useState('')
    const [total, setTotal] = React.useState('')
    const [approvedByDAO, setApprovedByDAO] = React.useState(false)
    const [allowance, setAllowance] = React.useState(0)
    const [allowanceusdt, setAllowanceusdt] = React.useState(0)

    const [loading, setLoading] = React.useState(false)
    const [purgatory, setPurgatory] = React.useState(false)
    const [hasdonated, setHasdonated] = React.useState(false)
    const [donations, setDonations] = React.useState([])
    const [admin, setAdmin] = React.useState('')



    const multicallHelper = async (abi,calls) => {
      const itf = new Interface(abi)
    
      const calldata = calls.map((call) => ({
        target: call.address.toLowerCase(),
        callData: itf.encodeFunctionData(call.name, call.params),
      }))

      return calldata 

    }

    
    React.useEffect(async() => {

      async function fetchData(){

        const web3 = new Web3(
            // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
            new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
          );
        const valid = await web3.utils.isAddress(address)

        // start fetching data if valid address 
        if(valid){
          // const web3 = new Web3(library.provider)
          // const contract = new web3.eth.Contract(ABI, address);
          const contract = new web3.eth.Contract(multicallabi, '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B');

          const calls = [
            {
              address: address,
              name: 'title',
              params: [],
            },
            {
              address: address,
              name: 'bannerUrl',
              params: [],
            },
            {
              address: address,
              name: 'description',
              params: [],
            },
            {
              address: address,
              name: 'location',
              params: [],
            },
            {
              address: address,
              name: 'category',
              params: [],
            },
            {
              address: address,
              name: 'softCap',
              params: [],
            },
            {
              address: address,
              name: 'totalAmount',
              params: [],
            },
            {
              address: address,
              name: 'approvedByDAO',
              params: [],
            },
            {
              address: address,
              name: 'name',
              params: [],
            },
            {
              address: address,
              name: 'endPurgatory',
              params: [],
            },
            {
              address: address,
              name: 'isFlagged',
              params: [],
            },
            {
              address: address,
              name: 'values',
              params: [],
            },
            {
              address: address,
              name: 'adminAddress',
              params:[]
            },
            {
              address: address,
              name: 'avatar',
              params: [],
            },
          ]

          let aggregatedData = await multicallHelper(ABI, calls)
          const itf = new Interface(ABI)

          let {returnData} = await contract.methods.aggregate(aggregatedData).call()
          const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

          console.log(res)



          setDescription(res[2])
          setTitle(res[0])
          setBannerUrl(res[1].toString().replace('ipfs.infura.io','gocryptome.infura-ipfs.io'))
          setName(res[8])
          setLocation(res[3])
          setCategory(res[4])
          setSoftcap(parseFloat(res[5]) / 10 ** 18)
          setTotal(parseFloat(res[6]) / 10 ** 18)
          setApprovedByDAO(res[7][0])
          setAdmin(res[12].toString())
          setAvatar(res[13] == '' ?  '/unknown.jpg' : res[13].toString().replace('ipfs.infura.io','gocryptome.infura-ipfs.io') )
          // setApprovedByDAO(true)
          if(Date.now() / 1000 < res[9].toString() && res[10][0] == false){
            setPurgatory(true)
          }

          const calls2 = []
          for(let i=0; i< res[11]; i++){
            calls2.push({
                address: address,
                name: 'messages',
                params: [i],
            })
          }
          let aggregatedData2 = await multicallHelper(ABI, calls2)
          console.log(calls2)
          let returnData2 = await contract.methods.aggregate(aggregatedData2).call()
          returnData2 = returnData2.returnData;
          const res2 = returnData2.map((call, i) => itf.decodeFunctionResult(calls2[i].name, call))

          let messages = []
          for(let i=0; i<res2.length; i++){
            messages.push(<>
            <div>
              <h4>{res2[i].nickname}</h4>
              <p>has donated <span style={{fontWeight:'bold'}}>${res2[i].amount.toString() / 10 **18}</span> </p>
              <p>{res2[i].message}</p>
              <hr />
            </div>
            </>)
          }
          setDonations(messages)

          if(account){
            const busdcontract = new web3.eth.Contract(tokenabi, "0xe9e7cea3dedca5984780bafc599bd69add087d56");
            const allowance = await busdcontract.methods.allowance(account, address).call()
            setAllowance(allowance)
            const usdtcontract = new web3.eth.Contract(tokenabi, "0x55d398326f99059ff775485246999027b3197955");
            const allowanceusdt = await usdtcontract.methods.allowance(account, address).call()
            setAllowanceusdt(allowanceusdt)
          }


        }
      }
      fetchData()
    }, [address,account])

  

    function nFormatter(num, digits) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
      }

      const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

      const truncateEthAddress = (address) => {
        if(address !== undefined){
        const match = address.match(truncateRegex);
        if (!match) return address;
        return `${match[1]}…${match[2]}`;
        }
      };


      const votefor = async() => {
        try{
          if(account){
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI, address);
      
           await contract.methods.vote('0').send({
             from: account
           })
      
          }
        }catch(err){
          alert("something went wrong")
        }
    
      }

      const voteAgainst = async() => {
        try{
          if(account){
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI, address);
      
           await contract.methods.vote('1').send({
             from: account
           })
      
          }
        }catch(err){
          alert("something went wrong")

        }
    
      }

      const flag = async() => {
        try{
          if(account){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI, address);
      
           await contract.methods.flag().send({
             from: account
           })
           setLoading(false)

          }
        }catch(err){
          alert("something went wrong")
          setLoading(false)

        }
    
      }

      const approveSpending = async() => {
        try{
          if(account){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(tokenabi, "0xe9e7cea3dedca5984780bafc599bd69add087d56");
      
            await contract.methods.approve(address, ethers.constants.MaxUint256).send({
              from: account
            })
            setLoading(false)
            setAllowance('100000000')
          }
        }catch(err){
          setLoading(false)
          alert("something went wrong")
        }
      }

      const approveUSDT = async() => {
        try{
          if(account){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(tokenabi, "0x55d398326f99059ff775485246999027b3197955");
      
            await contract.methods.approve(address, ethers.constants.MaxUint256).send({
              from: account
            })
            setAllowanceusdt('1000000')
            setLoading(false)
          }
        }catch(err){
          setLoading(false)
          alert("something went wrong")
        }
      }

      

      const [currency, setCurrency] = React.useState('BUSD')

      const [nickname, setNickname] = React.useState('')
      const [message, setMessage] = React.useState('')
      const [donation, setDonation] = React.useState('')


      // donate busd
      const donate = async() => {
        try{
          if(account){
            setLoading(true)
            setHasdonated(false)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI, address);
            const amountinwei = await web3.utils.toWei(donation, 'ether');
            if(currency == 'BUSD') {
              const busd = new web3.eth.Contract(tokenabi, "0xe9e7cea3dedca5984780bafc599bd69add087d56");
              const balanceUser = await busd.methods.balanceOf(account).call() 
              if(balanceUser < amountinwei){
                alert('You do not have enough BUSD balance in your wallet to make this donation!')
                setLoading(false)
              }
              else {
                await contract.methods.depositBUSD(amountinwei, nickname, message).send({
                  from: account
                })
              }

            } else {

              const usdt = new web3.eth.Contract(tokenabi, "0x55d398326f99059ff775485246999027b3197955");
              const balanceUser = await usdt.methods.balanceOf(account).call() 
              if(balanceUser < amountinwei){
                alert('You do not have enough USDT balance in your wallet to make this donation!')
                setLoading(false)
              }
              else {
              await contract.methods.depositUSDT(amountinwei, nickname, message).send({
                from: account
              })
            }
            }

            setLoading(false)
            setHasdonated(true)
          }
        }catch(err){
          setLoading(false)
          alert("something went wrong")
        }
      }

      const Withdraw = async() => {
        try{
          if(account){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI, address);
                  
            await contract.methods.finalWithdraw().send({
              from: account
            })
            setLoading(false)
          }
        }catch(err){
          console.log(err)
          setLoading(false)
          alert("something went wrong")
        }
      }

  return (
   <div>
         <div class="container-fluid" style={{background:'#005AFF'}}>
         <nav class="navbar navbar-expand-lg" style={{background:'transparent'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="100px" className="d-inline d-sm-none"  alt="logo" />
            <img src={Logo} width="156px" className="d-none d-md-inline"  alt="logo" />
            </a>
            
            <div>
              <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :                      <button class="btn btn-light" type="button" style={{ marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing.png" width="23px" alt="add" />Connect</button>
      }
                {/* <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'38px'}}><img src={misc} width="14px" /> Connect</button> */}
<a href="https://token.gocryptome.io/" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}><img src={orgtoken} width="30px" /> </button>
              </a>
            </div>
            
        </div>
      </nav>
           {/* <nav class="navbar navbar-expand-lg" style={{background:'#F9F9F9', boxShadow:'0px 4px 25px rgba(0, 0, 0, 0.09)'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="70" alt="logo" />
            GoCryptoMe</a>
            
            <form class="d-flex">

              <a href="/">
              <button class="btn btn-outline-primary" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :               <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Connect</button>}
<a href="https://gocryptome.io/" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}>$GCME Token</button>
              </a>
            </form>
        </div>
      </nav> */}

      </div>



      {/* MODAL SHAREL */}
      <div class="modal fade" id="modalShare" tabindex="-1" aria-labelledby="modalShare" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Share Fundraiser</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              
            <p style={{textAlign:'center'}}>Paste this fundraiser link anywhere</p>
            <img src="/socialsImages.png" width="100%" alt="socials" />
                        <div class="input-group">
                          <input type="text" class="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" value={window.location.href} onChange={(event) => event.preventDefault()} />
                          <button class="btn btn-primary" type="button" id="inputGroupFileAddon04" onClick={() => {navigator.clipboard.writeText(window.location.href)}}
                        >Copy</button>
                        </div>
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

       {/* MODAL CONNECT */}
       <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <p>Connected as {account}</p>
                <button className="btn btn-danger" style={{fontWeight:'bold', fontSize:'12px', width:"100%"}} onClick={() => {
              deactivate();
            }}>
                    Disconnect
                  </button>
                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
       {/* END MODAL CONNECT */}


        {/* CONTRIBUTE MODAL CONNECT */}
              <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Contribute</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <h6>You are about to contribute to this fundraise</h6>
                <p>Enter your donation</p>

                <p>You will go through 2 blockchain transactions <ul>
                  <li>Approve Spending</li>
                  <li>Donate</li>
                  <p style={{color:'red'}}>You need some BNB (BEP20) to pay gas fees</p>
                  <p style={{color:'red'}}>You must have enough USDT (BEP20) or BUSD (BEP20) in your wallet for donating</p>
                    </ul> </p>
                <input type="text" class="form-control" id="exampleFormControlInput1b" placeholder="Name" style={{background: "#005AFF", color:'white'}} onChange={(event) => setNickname(event.target.value)} />
                <input type="text" class="form-control" id="exampleFormControlInput1b" placeholder="Short Message" style={{background: "#005AFF", color:'white'}} onChange={(event) => setMessage(event.target.value)} />

                  <div class="input-group mb-3">
                  <input type="text" class="form-control" id="exampleFormControlInput1b" placeholder="100" style={{background: "#005AFF", color:'white'}} onChange={(event) => setDonation(event.target.value)} />
                  <span class="input-group-text" style={{background: "#005AFF", color:'white'}}>
                  <select class="form-select" aria-label="Default select example" onChange={(event) => setCurrency(event.target.value)}>
                    <option value="BUSD" selected>BUSD</option>
                    <option value="USDT">USDT</option>
                  </select>
                  </span>
                  </div>
                  {currency === 'BUSD' && allowance == "0" ?                   <button type="button" class="btn btn-primary" style={{width:'100%'}} disabled={loading} onClick={approveSpending} > {loading ? <div class="spinner-border text-light" role="status">
  <span class="visually-hidden">Loading...</span>
</div> : "Approve Spending"}   </button>
 :     currency === 'USDT' && allowanceusdt == '0' ? <button type="button" class="btn btn-primary" style={{width:'100%'}} disabled={loading} onClick={approveUSDT} > {loading ? <div class="spinner-border text-light" role="status">
 <span class="visually-hidden">Loading...</span>
</div> : "Approve Spending"}   </button> :            <>   <button type="button" class="btn btn-primary" style={{width:'100%'}} disabled={loading} onClick={donate}> 
                {loading ? <div class="spinner-border text-light" role="status">
  <span class="visually-hidden">Loading...</span>
</div> : "Donate"}   
                    </button>   

  </>
}               
              {hasdonated == true ?                   <div className="row">
                      <div className="col-12" style={{marginTop:'3%'}}>
                        <hr />
                        <h4>Thank you for your donation!</h4>
                        <p>Help by sharing the fundraising link anywhere on social media</p>
                        <div class="input-group">
                          <input type="text" class="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" value={window.location.href} onChange={(event) => event.preventDefault()} />
                          <button class="btn btn-primary" type="button" id="inputGroupFileAddon04" onClick={() => {navigator.clipboard.writeText(window.location.href)}}
                        >Copy</button>
                        </div>
                      </div>
                    </div> : null}

                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
       {/* END MODAL CONNECT */}

      <div className="container">
      <h1 style={{marginTop:'3%', fontSize:'1.5rem'}}>{title}</h1>
      <div className="row" style={{marginTop:'3%'}}>
        <div className="col-12 col-md-8 col-lg-8">
            <img src={bannerUrl} width="100%" alt="banner" style={{filter:'drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25))'}} />
              <div class="card" style={{boxShadow:'0px 4px 25px rgba(0, 0, 0, 0.1)', marginTop:'3%'}}>
                <div class="card-body">
                    <h3>About the fundraiser</h3>
                    <p> <a href={`https://bscscan.com/address/${address}`} target="_blank" rel="noreferrer">View on BscScan </a> </p>
                    <hr />
                    <p>{description}</p>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4">
        <div class="card" style={{boxShadow:'0px 4px 25px rgba(0, 0, 0, 0.1)'}}>
                <div class="card-body">
                  <div style={{float:'right', color:'blue', cursor:'pointer'}} type="button" data-bs-toggle="modal" data-bs-target="#modalShare">
                   Share <img src="/paste.png" width="20px" />
                  </div>
              <h3 style={{fontWeight:'bold'}}>  ${nFormatter(softcap)} Goal </h3>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style={{width:`${total/softcap*100}%`}} aria-valuenow={`${total/softcap*100}`} aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className="row">
                <div className="col-12" style={{marginTop:'3%'}}>
                  {approvedByDAO == false ? <> <h6>This cause is pending approval by the DAO.</h6>
                  <p>If you are a holder, cast your vote below</p>
                  <div className="row">
                    <div className="col">
                    <button class="btn btn-outline-success" type="button" style={{width:'100%', marginTop:'3%'}} onClick={votefor} >Vote FOR</button>
                    </div>
                    <div className="col">
                    <button class="btn btn-outline-danger" type="button" style={{width:'100%', marginTop:'3%'}} onClick={voteAgainst} >Vote AGAINST</button>
                      </div>
                  </div> </> :       <>        <button class="btn btn-primary" type="button" style={{width:'100%', marginTop:'3%'}} data-bs-toggle="modal" data-bs-target="#exampleModal2">Donate</button>
                    {admin && account && admin.toLowerCase() == account.toLowerCase()  ? <button type="button" class="btn btn-success" style={{width:'100%', marginTop:'3%'}} onClick={Withdraw} >Withdraw</button>  : null}
                      <hr />
                      {purgatory == true ? <button class="btn btn-outline-danger" type="button" style={{width:'100%', marginTop:'3%'}} onClick={flag}>Flag as Inappropriate</button> : null}
                  </>
}
                  
                </div>
              </div>
                </div>
        </div>
        <div class="card" style={{boxShadow:'0px 4px 25px rgba(0, 0, 0, 0.1)', marginTop:'3%'}}>
                <div class="card-body">
                <h3 style={{fontWeight:'bold'}}>  Organizer </h3>
                <div className="row">
                  <div className="col-2">
                    <img src={avatar} width="33px" alt="avatar" />
                  </div>
                  <div className="col-10">
                    Organized by {name} from {location}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <p style={{fontWeight:'bold', color:'green', textAlign:'center'}}>{category == 'Finerals and Memorials' ? 'Funerals and Memorials' : category}</p>
                </div>
                </div>
          </div>
          </div>

      </div>
      <div className="row" style={{marginTop:'3%'}}>
            <div className="col-12">
              <h3>Caring Words</h3>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              {donations}
            </div>
          </div>
      </div>

      <footer style={{background:'#005AFF', color:'white', height:'181px'}}>
      <div className="container">
            <br /> <br /><br />
            <div className="row">
                <div className="col-12 col-md-6">
                    <p style={{textAlign:'center'}}>© 2010-2022    GoCryptoMe
Terms   Privacy   Legal</p>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                        <div className="col-2 col-md-4" />
                        <div className="col-2 col-md-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/">
                        <img src={reddit} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>  
   </div>

  ); 
}

export default Analytics;