import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
import Web3 from 'web3'
import './App.css'
import Logo from './gcmelogo.png'
import Metamask from './metamask.png'
import socials from './socials.png'
import Wconnect from './walletconnect.png'
import { FileUpload } from 'react-ipfs-uploader'
import ABI from './dao.json';
import Bytecode from './bytecode.json'
import orgtoken from './orgtoken.png'
import reddit from './reddit.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import medium from './medium.png'

const axios = require('axios');

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}



function Homepage() {



  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  // set up block listener
  const [blockNumber, setBlockNumber] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (library) {
      let stale = false;

      console.log('fetching block number!!')
      library
        .getBlockNumber()
        .then(blockNumber => {
          if (!stale) {
            setBlockNumber(blockNumber);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(null);
          }
        });

      const updateBlockNumber = blockNumber => {
        setBlockNumber(blockNumber);
      };
      library.on("block", updateBlockNumber);

      return () => {
        library.removeListener("block", updateBlockNumber);
        stale = true;
        setBlockNumber(undefined);
      };
    }
  }, [library, chainId]);

  // fetch eth balance of the connected account
  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateEthAddress = (address) => {
    if(address !== undefined){
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
    }
  };

  React.useEffect(async() => {
    await axios.get('https://dry-plateau-94598.herokuapp.com')
  },[])

//   const [past, setPast] = React.useState([])

//   React.useEffect(async() => {
//     let x = await axios.get('https://dry-plateau-94598.herokuapp.com/past')
//     let things = []
//     for(let i=0; i<x.data.length; i++){
//       things.push(
//         <div className="card col-12 col-md-6 col-lg-4" >
//         <img class="card-img-top" src={x.data[i].image} alt="Card image cap" style={{maxHeight:'360px'}} />
//         <div class="card-body">
//           <h5 class="card-title">{x.data[i].title}</h5>
//           <p class="card-text">Raising {x.data[i].amount} BUSD</p>
//           <a href={`/fundraise/${x.data[i].address}`} class="btn btn-primary" style={{width:"100%"}}>Visit</a>

//         </div>
// </div>
//       )
      
//     }


//     setPast(things)

//   })

  const [country, setCountry] = React.useState('United States of America')
  const [firstname, setFirstname] = React.useState('')
  const [lastname, setLastname] = React.useState('')
  const [fundraiser, setFundraiser] = React.useState('')
  const [acceptterms, setAcceptterms] = React.useState(false)
  const [stage, setStage] = React.useState(0)
  const [bar, setBar] = React.useState(25)
  const [fileUrl, setFileUrl] = React.useState('')
  const [avatar, setAvatar] = React.useState('')

  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [outputurl, setOutputurl] = React.useState('')
  const [target, setTarget] = React.useState('0')
  const [fundraiseurl, setFundraiseurl] = React.useState('')

  
  const goNext = async() => {
    if(acceptterms == false){
      alert("accept the terms and condition")
    } else {
      if(firstname == '' || lastname == '' || fundraiser == ''){
        alert("input all details")
      } else {
          if(stage+1 == 3 && fileUrl == ''){
              alert("Click the upload button")
          } else {
            setStage(stage+1)
            switch(stage+1){
              case 1:
                setBar(50)
                break;
              case 2:
                setBar(75)
                break;
              case 3:
                setBar(100)
                break;
              default:
                setBar(25)
                break;
            }
          }


      }
    }
  }
  
  const goBack = async() => {
    setStage(stage-1)
    switch(stage-1){
      case 1:
        setBar(50)
        break;
      case 2:
        setBar(75)
        break;
      default:
        setBar(25)
        break;
    }
  }


  const [loading, setLoading] = React.useState(false)

  const releaseDAO = async() => {
    try{
      if(account && library){
        axios.get('https://dry-plateau-94598.herokuapp.com')
        setLoading(true)
        const web3 = new Web3(library.provider)
        const contract = new web3.eth.Contract(ABI);
  
        //   constructor(uint256 _raiseTarget, address _withdrawer, IBEP20 _token1, IBEP20 _token2, IBEP20 _token3, IBEP20 _governance, bytes32[] memory proposalNames, string memory _title, string memory _description, string memory _category, string memory banner, string memory _name, string memory _location) public {
        const amountToRaise = await web3.utils.toWei(target,'ether')
        const realName = `${firstname} ${lastname}`
        let x = await contract.deploy({
          data: Bytecode.object,
          arguments: [amountToRaise,account,"0xe9e7cea3dedca5984780bafc599bd69add087d56","0x55d398326f99059ff775485246999027b3197955","0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d","0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30",["0x415050524f564500000000000000000000000000000000000000000000000000","0x444953415050524f564500000000000000000000000000000000000000000000"],title, description, fundraiser, fileUrl, avatar, realName, country]
            }).send({
              from: account,
              gas:'5000000'
            })
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ address: x._address, image: fileUrl, title: title, amount: target, fundraiser: fundraiser})
          };

          await axios.post('https://dry-plateau-94598.herokuapp.com/recordInfo', { address: x._address, image: fileUrl, title: title, amount: target, fundraiser: fundraiser})
            // await fetch('https://dry-plateau-94598.herokuapp.com/recordInfo', requestOptions)

        setOutputurl(`https://gocryptome.io/fundraise/${x._address}`)
        setFundraiseurl(x._address)
        setStage(stage+1)
        setBar(100)
        setLoading(false)

            
      }
    }catch(err){
      console.log(err)
      alert('something went wrong')
      setLoading(false)
    }

  }
  const [showthething, setShowthething] = React.useState(false)

  return (
   <div>
         <div class="container-fluid" style={{background:'#005AFF'}}>
         <nav class="navbar navbar-expand-lg" style={{background:'transparent'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="100px" className="d-inline d-sm-none"  alt="logo" />
            <img src={Logo} width="156px" className="d-none d-md-inline"  alt="logo" />
            </a>
            
            <div>
            {/* {account ?               <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a> :               <>
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" onClick={() => setShowthething(true)} style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" onClick={() => setShowthething(true)} />
              </>
              } */}
              <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :                      <button class="btn btn-light" type="button" style={{ marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal">               <img src="/thing.png" width="23px" alt="add" /> Connect</button>
      }
                {/* <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'38px'}}><img src={misc} width="14px" /> Connect</button> */}
<a href="https://token.gocryptome.io" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}><img src={orgtoken} width="30px" /> </button>
              </a>
            </div>
            
        </div>
      </nav>




    </div>

      {/* <nav class="navbar navbar-expand-lg" style={{background:'#F9F9F9', boxShadow:'0px 4px 25px rgba(0, 0, 0, 0.09)'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="70" alt="logo" />
            GoCryptoMe</a>
            
            <form class="d-flex">

              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :               <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Connect</button>}
<a href="https://gocryptome.io/" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}>$GCME Token</button>
              </a>
            </form>
            
        </div>
      </nav> */}

      {/* MODAL CONNECT */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <p>Connected as {account}</p>
                <button className="btn btn-danger" style={{fontWeight:'bold', fontSize:'12px', width:"100%"}} onClick={() => {
              deactivate();
            }}>
                    Disconnect
                  </button>
                  <hr />
                  <a href="/mycampaigns">View my created campaigns</a>
                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
              setShowthething(false)
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
              setShowthething(false)
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      {/* MAIN */}

      <div className="container">
        {1 === 1 ? <> 
          <div className="row" style={{marginTop:'5%'}}>
          <div className="col-xs-12 col-md-4 col-lg-3" />
          <div className="col-xs-12 col-md-4 col-lg-5">
          {stage !== 5 ? <>           <h2>Start a campaign </h2>
          <div class="progress">
            <div class={`progress-bar w-${bar}`} role="progressbar" aria-valuenow={bar} aria-valuemin="0" aria-valuemax="100"></div>
          </div> </>: null}

          <br />
          {stage == 0 ? <> 
            <h5 style={{color:'#555555'}}>Fill out the form below</h5>
          <div class="card" style={{border:'1px solid blue'}}>
            <div class="card-body">
              <h6 style={{color:'blue'}}>What is your name?</h6>
              <div className="row">
                <div className="col">
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="First Name" onChange={(event) => setFirstname(event.target.value)} />
                </div>
                <div className="col">
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Last Name" onChange={(event) => setLastname(event.target.value)} />
                </div>
              </div>
              <br />
              <h6 style={{color:'blue'}}>Where do you live?</h6>
              <select class="form-select" onChange={(event) => setCountry(event.target.value)} aria-label="Default select example">
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">Netherlands (Holland, Europe)</option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">Republic of Montenegro</option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">United Arab Emirates</option>
                <option value="United States of America" selected>United States of America</option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <br />
              <h6 style={{color:'blue'}}>What are you fundraising for?</h6>
              <select class="form-select" aria-label="Default select example" onChange={(event) => setFundraiser(event.target.value)}>
              <option value="0" selected>Click to choose a category</option><option value="Accidents and Emergencies">Accidents &amp; Emergencies</option><option value="Animals and Pets">Animals &amp; Pets</option><option value="Babies, Children and Family">Babies, Children &amp; Family</option><option value="Business and Entrepreneurs">Business &amp; Entrepreneurs</option><option value="Celebrations and Events">Celebrations &amp; Events</option><option value="Community and Neighbours">Community &amp; Neighbours</option><option value="Competitions and Pageants">Competitions &amp; Pageants</option><option value="Creative Arts, Music and Film">Creative Arts, Music &amp; Film</option><option value="Dreams, Hopes and Wishes">Dreams, Hopes &amp; Wishes</option><option value="Education and Learning">Education &amp; Learning</option><option value="Environment">Environment</option><option value="Finerals and Memorials">Funerals &amp; Memorials</option><option value="Medical, Illness and Healing">Medical, Illness &amp; Healing</option><option value="Missions, Faith and Church">Missions, Faith &amp; Church</option><option value="Rent, Food and Monthly Bills">Rent, Food &amp; Monthly Bills</option><option value="Sports, Teams and Clubs">Sports, Teams &amp; Clubs</option><option value="Travel and Adventure">Travel &amp; Adventure</option><option value="Volunteer and Service">Volunteer &amp; Service</option><option value="Weddings and Honeymoons">Weddings &amp; Honeymoons</option><option value="Other">Other</option></select>
            </div>
          </div>
          <br />
          <div class="form-check" >
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={(event) => setAcceptterms(event.target.checked)} />
            <label class="form-check-label" for="flexCheckDefault">
            By continuing, you agree to <a href="#">GoCryptoMe </a> terms and privacy policy.
            </label>
          </div>
          {account ?           <button class="btn btn-primary" type="button" style={{width:'100%'}} onClick={goNext}>Next</button>
            :           <button class="btn btn-outline-dark" type="button" style={{ marginLeft:'5px', width:'100%'}} data-bs-toggle="modal" data-bs-target="#exampleModal">               <img src="/thing.png" width="23px" alt="add" /> Connect</button>         }
          </> : stage == 1 ? <div>
          <h5 style={{color:'#555555'}}>Set your fundraising goal </h5>
          <div class="card" style={{border:'1px solid blue'}}>
            <div class="card-body">
              <h6 style={{color:'blue'}}>How much would you like to raise?</h6>
              <div class="input-group mb-3">
                <span class="input-group-text" style={{background:'white'}}>$</span>
                <input type="text" class="form-control" placeholder="Enter your goal amount" onChange={(event) => setTarget(event.target.value)} />
                <span class="input-group-text" style={{background:'white'}}>BUSD</span>
              </div>
              <p style={{color:'blue'}}>
              Keep in mind that transaction bsc fees may apply
              </p>
              <div class="alert alert-primary" role="alert">
                To receive the raised funds, please make sure the individual withdrawing has a crypto wallet.
              </div>
            </div>
          </div>
            <br />          <div className="row">
            <div className="col">
                <button class="btn btn-outline-primary" type="button" style={{width:'100%'}} onClick={goBack}>Go Back</button>

                </div>
                <div className="col">
                <button class="btn btn-primary" type="button" style={{width:'100%'}} onClick={goNext}>Next</button>
                </div>
              </div>        
            </div>  : stage == 2 ? <>       <h5 style={{color:'black'}}>Add a cover photo or video</h5>
            <p style={{color:'#555555'}}>A high-quality photo or video will help tell your story and build trust with donors. 800x400 (recommended)</p>
            <div class="card" style={{border:'1px solid blue'}}>
              <div class="card-body">
              <FileUpload setUrl={setFileUrl} />

              </div>
            </div>
            <hr />
            <h5 style={{color:'black'}}>Add your avatar</h5>
            <p style={{color:'#555555'}}>A high-quality photo. 100x100 (recommended)</p>
            <div class="card" style={{border:'1px solid blue'}}>
              <div class="card-body">
              <FileUpload setUrl={setAvatar} />

              </div>
            </div>
            <br />          <div className="row">
            <div className="col">
                <button class="btn btn-outline-primary" type="button" style={{width:'100%'}} onClick={goBack}>Go Back</button>

                </div>
                <div className="col">
                <button class="btn btn-primary" type="button" style={{width:'100%'}} onClick={goNext}>Next</button>
                </div>
              </div>    </> : stage == 3 ? <>
              <h5 style={{color:'black'}}>You're almost there!</h5>
              <h6 style={{color:'blue'}}>What is your fundraiser title?</h6>
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Title" onChange={(event) => setTitle(event.target.value)} />
              <br />
              <h6 style={{color:'blue'}}>Why are you fundraising? (max 1000 characters)</h6>
              <textarea class="form-control" id="exampleFormControlTextarea1" onChange={(event) => setDescription(event.target.value)} placeholder="We are raising funds for the tragic event that occured in our family 2 weeks ago. Funds will help us cover incurred costs and survive for the next 2 months." rows="5"></textarea>
             <div className="row" style={{marginTop:'3%'}}>
              <div className="col">
                <button class="btn btn-outline-primary" type="button" style={{width:'100%'}} onClick={goBack}>Go Back</button>

                </div>
                <div className="col">
                  {loading ?                 <button class="btn btn-primary" type="button" style={{width:'100%'}} disabled={loading} onClick={releaseDAO}>
                  <div class="spinner-border text-light" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
                  </button>
                  :                 <button class="btn btn-primary" type="button" style={{width:'100%'}} disabled={loading} onClick={releaseDAO}>Create</button>  
                }
                </div>
              </div> 
              </> :               <div class="card" style={{border:'1px solid blue'}}>
            <div class="card-body">
              <h5>It's done!</h5>
              <div class="alert alert-danger" role="alert">
                <p style={{fontWeight:'bold'}}>Warning: Please save/bookmark your fundraiser address link to retrieve it.</p>
                </div>
              <h4>
                Help by sharing your fundraiser link</h4>
                <hr />
                <div class="alert alert-primary" role="alert" style={{textAlign:'center'}}>
                  <img src={socials} alt="socials" width="100%" style={{maxWidth:'200px'}} />
                  <br />
                Paste this fundraiser link anywhere
                </div>
                <div class="input-group">
  <input type="text" class="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" value={outputurl} onChange={(event) => event.preventDefault()} />
  <button class="btn btn-primary" type="button" id="inputGroupFileAddon04" onClick={() => {navigator.clipboard.writeText(outputurl)}}
>Copy</button>
</div>
<a href={`/fundraise/${fundraiseurl}`} >
<button class="btn btn-primary" type="button" id="inputGroupFileAddon04" style={{marginTop:'3%', width:'100%'}} 
>Complete</button>
</a>
              </div>
              </div> }

              

            </div>
          
          <div className="col-xs-12 col-md-4 col-lg-4" />


        </div>
        </> :         <div className="row" style={{textAlign:'center', marginTop:'3%'}}>
            {/* <h4>Connect your Wallet</h4>
            <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{width:'100%', maxWidth:'407px', margin:'0 auto'}}>Connect</button>
            <a href="https://gocryptome.io" target="_blank" rel="noreferrer" style={{marginTop:'2%'}}> <button class="btn btn-outline-primary" type="button" style={{width:'100%', maxWidth:'407px'}}>Go Back</button> </a> */}
            <img src="/bg.jpg" width="100%" />
        </div>}


      </div>

      <hr /> 
      {/* <div className="container">
        <h4>Recently Created Sales</h4>
        <div className="row">
          {past}
        </div>
      </div> */}

      <footer style={{background:'#005AFF', color:'white', height:'181px'}}>
      <div className="container">
            <br /> <br /><br />
            <div className="row">
                <div className="col-12 col-md-6">
                    <p style={{textAlign:'center'}}>© 2010-2022    GoCryptoMe
Terms   Privacy   Legal</p>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                        <div className="col-2 col-md-4" />
                        <div className="col-2 col-md-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/">
                        <img src={reddit} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>   

   </div>

  ); 
}

export default Homepage;