import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
import Web3 from 'web3'
import './App.css'
import Logo from './gcmelogo.png'
import misc from './misc.png'
import orgtoken from './orgtoken.png'
import rightimg from './rightimg.png'
import reddit from './reddit.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import medium from './medium.png'
import divider from './divider.png'
import bluebar from './bluebar.png'
import Metamask from './metamask.png'
import Wconnect from './walletconnect.png'

const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

const truncateEthAddress = (address) => {
  if(address !== undefined){
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
  }
};


const axios = require('axios');




function Homepage2() {

  const connectorsByName = {
    Injected: injected,
    WalletConnect: walletconnect
  };
  
  
    const context = useWeb3React();
    const {
      connector,
      library,
      chainId,
      account,
      activate,
      deactivate,
      active,
      error
    } = context;
  
    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = React.useState();
    React.useEffect(() => {
      console.log('running')
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);
  
    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();
  
    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);
  
    // set up block listener
    const [blockNumber, setBlockNumber] = React.useState();
    React.useEffect(() => {
      console.log('running')
      if (library) {
        let stale = false;
  
        console.log('fetching block number!!')
        library
          .getBlockNumber()
          .then(blockNumber => {
            if (!stale) {
              setBlockNumber(blockNumber);
            }
          })
          .catch(() => {
            if (!stale) {
              setBlockNumber(null);
            }
          });
  
        const updateBlockNumber = blockNumber => {
          setBlockNumber(blockNumber);
        };
        library.on("block", updateBlockNumber);
  
        return () => {
          library.removeListener("block", updateBlockNumber);
          stale = true;
          setBlockNumber(undefined);
        };
      }
    }, [library, chainId]);
  
    // fetch eth balance of the connected account
    const [ethBalance, setEthBalance] = React.useState();
    React.useEffect(() => {
      if (library && account) {
        let stale = false;
  
        library
          .getBalance(account)
          .then(balance => {
            if (!stale) {
              setEthBalance(balance);
            }
          })
          .catch(() => {
            if (!stale) {
              setEthBalance(null);
            }
          });
  
        return () => {
          stale = true;
          setEthBalance(undefined);
        };
      }
    }, [library, account, chainId]);
  
  
    const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  
    const truncateEthAddress = (address) => {
      if(address !== undefined){
      const match = address.match(truncateRegex);
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
      }
    };
    
  const [trending, setTrending] = React.useState([])
  const [topdonated, setTopDonated] = React.useState([])
  const [cat1, setCat1] = React.useState([])
  const [cat2, setCat2] = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const [showthething, setShowthething] = React.useState(false)


  return (
   <div>

    <div class="container-fluid" style={{background:'#005AFF'}}>
    <nav class="navbar navbar-expand-lg" style={{background:'transparent'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="100px" className="d-inline d-sm-none"  alt="logo" />
            <img src={Logo} width="156px" className="d-none d-md-inline"  alt="logo" />
            </a>
            
            <div>
            {/* {account ?               <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a> :               <>
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" onClick={() => setShowthething(true)} style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" onClick={() => setShowthething(true)} />
              </>
              } */}
              <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :                      <button class="btn btn-light" type="button" style={{ marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing.png" width="23px" alt="add" /> Connect</button>
      }
                {/* <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'38px'}}><img src={misc} width="14px" /> Connect</button> */}
<a href="https://token.gocryptome.io/" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}><img src={orgtoken} width="30px" /> </button>
              </a>
            </div>
            
        </div>
      </nav>




    </div>

      {/* MODAL CONNECT */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <p>Connected as {account}</p>
                <button className="btn btn-danger" style={{fontWeight:'bold', fontSize:'12px', width:"100%"}} onClick={() => {
              deactivate();
            }}>
                    Disconnect
                  </button>
                  <hr />
                  <a href="/mycampaigns">View my created campaigns</a>
                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
              setShowthething(false)
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
              setShowthething(false)
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>



    

    <br /> <br />
    <div style={{background:'white'}}>
    <div className="container" style={{backgorund:'transparent'}}>
        <br /> <br />
        <img src={bluebar} style={{ width:'100%', maxWidth:'64px'}} />
        <h2 style={{fontWeight:'bold', marginBottom:'4%'}}>All Categories</h2>
          <div className="row">
          <div className="col-12 col-md-6">
            <a href='/topfunds/Accidents and Emergencies' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Accidents & Emergencies
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Animals and Pets' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Animals & Pets
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>



            <div className="col-12 col-md-6">
            <a href='/topfunds/Babies, Children and Family' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Babies, Children and Family
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Business and Entrepreneurs' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Business and Entrepreneurs
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Celebrations and Events' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Celebrations and Events
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>




            <div className="col-12 col-md-6">
            <a href='/topfunds/Community and Neighbours' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Community and Neighbours
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Competitions and Pageants' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Competitions and Pageants
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Creative Arts, Music and Film' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Creative Arts, Music and Film
                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Dreams, Hopes and Wishes' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Dreams, Hopes and Wishes              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>



            <div className="col-12 col-md-6">
            <a href='/topfunds/Education and Learning' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Education and Learning                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Environment' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Environment
                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Finerals and Memorials' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Funerals and Memorials
                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Medical, Illness and Healing' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Medical, Illness and Healing                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Missions, Faith and Church' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Missions, Faith and Church                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>

            <div className="col-12 col-md-6">
            <a href='/topfunds/Rent, Food and Monthly Bills' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Rent, Food and Monthly Bills                            </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Sports, Teams and Clubs' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Sports, Teams and Clubs                          </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Travel and Adventure' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Travel and Adventure                          </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Volunteer and Service' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Volunteer and Service                        </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Weddings and Honeymoons' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Weddings and Honeymoons                     </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


            <div className="col-12 col-md-6">
            <a href='/topfunds/Other' style={{textDecoration:'none', color:'white'}} >
        <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Other                     </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          </div> 
      </div>
        </a>
            </div>


          </div>
        {/* <div class="row">
        <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Business & Entrepreneurs</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
        <a href="/topfunds/Business & Entrepreneurs">
        <button className="btn btn-text" style={{width:'100%', color:'white'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
        </button>
        </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Environment</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Environment">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
            <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Pets & Animals</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Animals and Pets">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
            <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Travel & Adventure</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Travel & Adventure">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>

  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Rent, Food & Monthly Bills</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Rent, Food & Monthly Bills">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>

  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Other</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Other">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>


</div> */}



<br /> <br /> 

    </div>
    </div>
    {showthething==false ? null :                 <div className="alert" style={{background:'#DFEAFF', textAlign:'center', color:'black', width:'314px', position:'absolute', right:'24px', top:'80px'}}>
                      <h4 style={{fontSize:'18px'}}>Please connect your wallet to create a campaign.</h4>
                      <button class="btn btn-primary" type="button" style={{marginRight:'5px', marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing2.png" width="23px" alt="add" /> Connect</button>
                    </div>}

    <footer style={{background:'#005AFF', color:'white', height:'181px'}}>
    <div className="container">
            <br /> <br /><br />
            <div className="row">
                <div className="col-12 col-md-6">
                    <p style={{textAlign:'center'}}>© 2010-2022    GoCryptoMe
Terms   Privacy   Legal</p>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                        <div className="col-2 col-md-4" />
                        <div className="col-2 col-md-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/">
                        <img src={reddit} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>     
   </div>

  ); 
}

export default Homepage2;