import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
import Web3 from 'web3'
import './App.css'
import Logo from './gcmelogo.png'
import misc from './misc.png'
import orgtoken from './orgtoken.png'
import rightimg from './rightimg.png'
import reddit from './reddit.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import medium from './medium.png'
import divider from './divider.png'
import bluebar from './bluebar.png'
import Metamask from './metamask.png'
import socials from './socials.png'
import Wconnect from './walletconnect.png'




const axios = require('axios');




function Homepage2() {


const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect
};


  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  // set up block listener
  const [blockNumber, setBlockNumber] = React.useState();
  React.useEffect(() => {
    console.log('running')
    if (library) {
      let stale = false;

      console.log('fetching block number!!')
      library
        .getBlockNumber()
        .then(blockNumber => {
          if (!stale) {
            setBlockNumber(blockNumber);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(null);
          }
        });

      const updateBlockNumber = blockNumber => {
        setBlockNumber(blockNumber);
      };
      library.on("block", updateBlockNumber);

      return () => {
        library.removeListener("block", updateBlockNumber);
        stale = true;
        setBlockNumber(undefined);
      };
    }
  }, [library, chainId]);

  // fetch eth balance of the connected account
  const [ethBalance, setEthBalance] = React.useState();
  React.useEffect(() => {
    if (library && account) {
      let stale = false;

      library
        .getBalance(account)
        .then(balance => {
          if (!stale) {
            setEthBalance(balance);
          }
        })
        .catch(() => {
          if (!stale) {
            setEthBalance(null);
          }
        });

      return () => {
        stale = true;
        setEthBalance(undefined);
      };
    }
  }, [library, account, chainId]);


  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateEthAddress = (address) => {
    if(address !== undefined){
    const match = address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
    }
  };


    
  const [trending, setTrending] = React.useState([])
  const [topdonated, setTopDonated] = React.useState([])
  const [cat1, setCat1] = React.useState([])
  const [cat2, setCat2] = React.useState([])

  const [loading, setLoading] = React.useState(true)
  React.useEffect(async() => {
    let x = await axios.get('https://dry-plateau-94598.herokuapp.com/v1/trending')
    let y = await axios.get('https://dry-plateau-94598.herokuapp.com/v1/all')
    // let z = await axios.get(`https://dry-plateau-94598.herokuapp.com/v1/fundraiser/Animals and Pets`)
    // let z2 = await axios.get(`https://dry-plateau-94598.herokuapp.com/v1/fundraiser/Business and Entrepreneurs`)

    let things = [] // trending campaigns
    let things2 = [] // top donated
    let things3 = [] // 
    let things4 = [] // 

    let arrayCategoriesToShow = []
    let categories = ['Accidents and Emergencies','Animals and Pets','Babies, Children and Family','Business and Entrepreneurs','Celebrations and Events','Community and Neighbours','Competitions and Pageants','Creative Arts, Music and Film','Dreams, Hopes and Wishes','Education and Learning','Environment','Finerals and Memorials','Medical, Illness and Healing','Missions, Faith and Church','Rent, Food and Monthly Bills','Sports, Teams and Clubs','Travel and Adventure','Volunteer and Service','Weddings and Honeymoons','Other']
    for(let i=0; i<categories.length;i++){
      // let schh = await axios.get(`https://dry-plateau-94598.herokuapp.com/v1/fundraiser/${categories[i]}`)

      // let localArray = []
      // if(schh.data.length > 3){
      //   for(let j=0; j<3; j++){
      //     localArray.push(
      //       <a href={`/fundraise/${schh.data[j].address}`} style={{textDecoration:'none', color:'black'}} >
      //         <div style={{background:'#E9F0FE', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
      //             {schh.data[j].title}
      //         </div>
      //       </a>
      //     )
      //   }
      // } else {
      //   for(let j=0; j<schh.data.length; j++){
      //     localArray.push(
      //       <a href={`/fundraise/${schh.data[j].address}`} style={{textDecoration:'none', color:'black'}} >
      //         <div style={{background:'#E9F0FE', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
      //             {schh.data[j].title}
      //         </div>
      //       </a>
      //     )
      //   }
      // }

      
      arrayCategoriesToShow.push(
                    <div className="col-12 col-md-6">
                  {/* {localArray} */}
                  <a href={`/topfunds/${categories[i]}`} style={{textDecoration:'none', color:'white'}} >
              <div style={{background:'#005AFF', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
              <div className="row">
                    <div className="col-9 col-md-11">
                    {categories[i] == 'Finerals and Memorials' ? 'Funerals and Memorials' : categories[i]}
                    </div>
                    <div className="col-3 col-md-1">
                    <img src="/arrow.png" alt="arrow" width="25px" />
                    </div>
                
                </div> 
            </div>
              </a>
                  </div>
      )
    }

    setCat1(arrayCategoriesToShow)

    // x.data[i]
    for(let i=0; i<3; i++){
      things.push(
        <div class="col-12 col-md-4 col-lg-4">
          {/* rgb(206 222 252) -50px 50px 0px -20px */}
  <div class="card" style={{widht:'100%', maxWidth:'391px', height:'450px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 5px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
      <img src={x.data[i].image.replace('ipfs.infura.io','gocryptome.infura-ipfs.io')} style={{width:'100%', height:'246px', maxHeight:'246px', margin:'0 auto', display:'block', objectFit:'cover'}} class="card-img-top" alt="..." />
      <div class="card-body">
        <p class="card-text">{x.data[i].title}</p>
        <h6 style={{color:'blue'}}>Raising ${x.data[i].amount} USD </h6>
      </div>
      <div class="card-footer" style={{background:'#005AFF', borderRadius:'5px'}}>
          <a href={`/fundraise/${x.data[i].address}`}>
        <button className="btn btn-text" style={{width:'100%', color:'white'}}> 
        <div className="row">
              <div className="col-11">
              Visit Fundraiser
              </div>
              <div className="col-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
          </button> 
        </a>
      </div>
    </div>
  </div>
      )

      things2.push(
        <div class="col-12 col-md-4 col-lg-4">
        <div class="card" style={{widht:'100%', maxWidth:'391px', height:'450px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
        <img src={y.data[i].image.replace('ipfs.infura.io','gocryptome.infura-ipfs.io')} style={{width:'100%', height:'246px', maxHeight:'246px', margin:'0 auto', display:'block', objectFit:'cover'}} class="card-img-top" alt="..." />
            <div class="card-body">
            <p class="card-text">{y.data[i].title}</p>
            <h6 style={{color:'blue'}}>Raising ${y.data[i].amount} USD </h6>
            </div>
            <div class="card-footer" style={{background:'#005AFF', borderRadius:'5px'}}>
            <a href={`/fundraise/${y.data[i].address}`}>
              <button className="btn btn-text" style={{width:'100%', color:'white'}}>
              <div className="row">
              <div className="col-11">
              Visit Fundraiser
              </div>
              <div className="col-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
              </button>
              </a>
            </div>
          </div>
        </div>
      )
      
    }

    // for(let i=0; i<4; i++){
    //   things3.push(
    //     <a href={`/fundraise/${z.data[i].address}`} style={{textDecoration:'none', color:'black'}} >
    //     <div style={{background:'#E9F0FE', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
    //         {z.data[i].title}
    //     </div>
    //     </a>
    //   )
    //   things4.push(
    //     <a href={`/fundraise/${z.data[i].address}`} style={{textDecoration:'none', color:'black'}} >
    //     <div style={{background:'#E9F0FE', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05)', borderRadius:'5px', padding:'25px', marginTop:'3%', textAlign:'center', fontWeight:'bold'}}>
    //       {z2.data[i].title}
    //   </div>
    //     </a>
    //   )

    // }



    setTrending(things)
    setTopDonated(things2)
    setLoading(false)
    // setCat1(things3)
    // setCat2(things4)

  }, [])

  const [showthething, setShowthething] = React.useState(false)

  return (
   <div>

    <div class="container-fluid" style={{background:'#005AFF'}}>
    <nav class="navbar navbar-expand-lg" style={{background:'transparent'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="100px" className="d-inline d-sm-none"  alt="logo" />
            <img src={Logo} width="156px" className="d-none d-md-inline"  alt="logo" />
            </a>
            
            <div>
              {/* {account ?               <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a> :               <>
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" onClick={() => setShowthething(true)} style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" onClick={() => setShowthething(true)} />
              </>
              } */}
              
              <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :                      <button class="btn btn-light" type="button" style={{marginRight:'5px', marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing.png" width="23px" alt="add" /> Connect</button>
      }
                {/* <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'38px'}}><img src={misc} width="14px" /> Connect</button> */}
<a href="https://token.gocryptome.io" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}><img src={orgtoken} width="30px" /> </button>
              </a>
            </div>
            
        </div>
      </nav>

      
      {/* MODAL CONNECT */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <p>Connected as {account}</p>
                <button className="btn btn-danger" style={{fontWeight:'bold', fontSize:'12px', width:"100%"}} onClick={() => {
              deactivate();
            }}>
                    Disconnect
                  </button>
                  <hr />
                  <a href="/mycampaigns">View my created campaigns</a>

                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
              setShowthething(false)
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
              setShowthething(false)
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row" >
      
          <div className="col-12 d-block d-sm-none">
          <img src={rightimg} width="100%" style={{maxWidth:'503px'}} />

          </div>

          <div className="col-12 col-md-12 col-lg-2" />
          <div className="col-12 d-block d-sm-none">
          <div style={{color:'white'}}>
          {showthething==false ? null :                 <div className="alert" style={{background:'#DFEAFF', textAlign:'center', color:'black', width:'314px', position:'absolute', right:'24px', top:'80px'}}>
                      <h4 style={{fontSize:'18px'}}>Please connect your wallet to create a campaign.</h4>
                      <button class="btn btn-primary" type="button" style={{marginRight:'5px', marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing2.png" width="23px" alt="add" /> Connect</button>
                    </div>}
                    <h1 style={{fontWeight:'600', textAlign:'center'}}>The Future of Uncensored
                    Crowdfunding.</h1>
                    <p style={{textAlign:'center', marginTop:'3%'}}>
                    The #1 DAO operated, blockchain powered, crowdfunding platform
                    </p>
                    <a href="https://token.gocryptome.io" target="_blank" rel="noreferrer">
                    <button class="btn btn-light" type="button"  style={{width:'100%', maxWidth:'427px', fontSize:'23px', color:'#005AFF', margin:'0 auto', display:'block', marginTop:'3%'}}>Invest in the $GCME Ecosystem<img src="/arrowright.png" width="15px" /></button> 
                    </a>
                    <div className="row" style={{marginTop:'5%', marginBottom:'5%'}}>
                        <div className="col-2" />
                        <div className="col-2">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%"  />
                        </a>
                        </div>
                        <div className="col-2">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%"  />
                        </a>
                        </div>
                        <div className="col-2">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%"  />
                        </a>
                        </div>
                        <div className="col-2">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/" target="_blank" rel="noreferrer">
                        <img src={reddit} width="100%" />
                        </a>
                        </div>
                    </div>
                </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-none d-lg-block" style={{marginTop:'5%'}}>
                <div style={{color:'white'}}>
                {showthething==false ? null :             <div className="alert" style={{background:'#DFEAFF', textAlign:'center', color:'black', width:'314px', position:'absolute', right:'100px', top:'80px'}}>
                      <h4 style={{fontSize:'18px'}}>Please connect your wallet to create a campaign.</h4>
                      <button class="btn btn-primary" type="button" style={{marginRight:'5px', marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"> <img src="/thing2.png" width="23px" alt="add" /> Connect</button>
                    </div>}

                    <h1 style={{fontWeight:'600'}}>The Future of Uncensored
                    Crowdfunding.</h1>
                    <h3>
                    The #1 DAO operated, blockchain powered, crowdfunding platform
                    </h3>
                    <a href="https://token.gocryptome.io" target="_blank" rel="noreferrer">
                    <button class="btn btn-light" type="button"  style={{width:'100%', maxWidth:'427px', fontSize:'23px', color:'#005AFF', margin:'0 auto', marginTop:'3%'}}> Invest in the $GCME ecosystem <img src="/arrowright.png" width="15px" /> </button>
                    </a>
                    <div className="row" style={{marginTop:'3%'}}>
                        <div className="col-2 col-md-1 col-lg-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%"  />
                        </a>                        </div>
                        <div className="col-2 col-md-1 col-lg-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%"  />
                        </a>                        </div>
                        <div className="col-2 col-md-1 col-lg-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%"  />
                        </a>                        </div>
                        <div className="col-2 col-md-1 col-lg-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/" target="_blank" rel="noreferrer">
                        <img src={reddit} width="100%" />
                        </a>                        </div>
                    </div>
                </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                <img src={rightimg} width="100%" style={{maxWidth:'503px'}} />
              </div>
      </div>
      <div className="col-12">
            <img src={divider} style={{margin:'0 auto', display:'block', width:'100%', maxWidth:'154px'}} />
        </div>
        <br /> <br />
    </div>

    <div style={{background:'white'}}>
    <div className="container" style={{backgorund:'transparent'}}>
        <br /> <br />
        <img src={bluebar} style={{ width:'100%', maxWidth:'64px'}} />
        <h2 style={{fontWeight:'bold', marginBottom:'4%'}}>Trending Campaigns</h2>

        <div class="row">
          {loading ?                 <img src="/gif.gif" alt="this slowpoke moves"  style={{width:'25%', margin:'0 auto', display:'block'}} /> : trending}
        </div>
<div class="col-12" style={{textAlign:'right'}}>
 <a href="/trending" style={{textDecoration:'none'}}>See All <img src="/arrowright.png" width="15px" /></a>
</div>
    </div>
    </div>

    <br /> <br /> 


    <div class="div" style={{background:'#EFF4FC'}}>
        <div className="container" style={{backgorund:'transparent'}}>
        <br /> <br />
        <img src={bluebar} style={{ width:'100%', maxWidth:'64px'}} />
        <h2 style={{fontWeight:'bold', marginBottom:'4%'}}>Top Donated</h2>

        <div class="row">
                  {loading ?                 <img src="/gif.gif" alt="this slowpoke moves"  style={{width:'25%', margin:'0 auto', display:'block'}} /> : topdonated}

</div>
<div class="col-12" style={{textAlign:'right'}}>
 <a href="/top-donated" style={{textDecoration:'none'}}>See All <img src="/arrowright.png" width="15px" /></a>
 <br /> <br /> 
</div>
    </div>
    </div>

    <br /> <br />
    <div style={{background:'white'}}>
    <div className="container" style={{backgorund:'transparent'}}>
        <br /> <br />
        <img src={bluebar} style={{ width:'100%', maxWidth:'64px'}} />
        <h2 style={{fontWeight:'bold', marginBottom:'4%'}}>Browse By Category</h2>
          <div className="row">
            {cat1}
          </div>
        {/* <div class="row">
        <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Business & Entrepreneurs</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
        <a href="/topfunds/Business & Entrepreneurs">
        <button className="btn btn-text" style={{width:'100%', color:'white'}}>
        <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
        </button>
        </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Environment</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Environment">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
            <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Pets & Animals</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Animals and Pets">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
            <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>
  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Travel & Adventure</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Travel & Adventure">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>

  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Rent, Food & Monthly Bills</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Rent, Food & Monthly Bills">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>

  <div class="col-6 col-md-4 col-lg-4 mt-2">
  <div class="card" style={{widht:'100%', height:'267px', maxWidth:'391px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 10px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
  <div class="card-body" style={{    display: "flex", alignItems: "center",margin: "0 auto", fontWeight:'bold'}}>
        <p class="card-text" style={{textAlign:'center'}}>Other</p>
      </div>
      <div class="card-footer" style={{background:'#005AFF'}}>
      <a href="/topfunds/Other">
      <button className="btn btn-text" style={{width:'100%', color:'white'}}>
      <div className="row">
              <div className="col-9 col-md-11">
              Top Funds
              </div>
              <div className="col-3 col-md-1">
              <img src="/arrow.png" alt="arrow" width="25px" />
              </div>
          
          </div> 
      </button>
      </a>
      </div>
    </div>
  </div>


</div> */}


<div class="col-12" style={{textAlign:'right', marginTop:'5%'}}>
 <a href="/all-categories" style={{textDecoration:'none'}}>See All <img src="/arrowright.png" width="15px" /></a>
</div>
<br /> <br /> 

    </div>
    </div>

    <footer style={{background:'#005AFF', color:'white', height:'181px'}}>
        <div className="container">
            <br /> <br /><br />
            <div className="row">
                <div className="col-12 col-md-6">
                    <p style={{textAlign:'center'}}>© 2010-2022    GoCryptoMe
Terms   Privacy   Legal</p>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                        <div className="col-2 col-md-4" />
                        <div className="col-2 col-md-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/">
                        <img src={reddit} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>   
   </div>

  ); 
}

export default Homepage2;