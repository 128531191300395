import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
import Web3 from 'web3'
import './App.css'
import Logo from './gcmelogo.png'
import misc from './misc.png'
import orgtoken from './orgtoken.png'
import rightimg from './rightimg.png'
import reddit from './reddit.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import medium from './medium.png'
import divider from './divider.png'
import bluebar from './bluebar.png'
import Metamask from './metamask.png'
import Wconnect from './walletconnect.png'
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

const truncateEthAddress = (address) => {
  if(address !== undefined){
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
  }
};


const axios = require('axios');


const connectorsByName = {
    Injected: injected,
    WalletConnect: walletconnect
  };
  
  function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network.";
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return "Please authorize this website to access your Ethereum account.";
    } else {
      console.error(error);
      return "An unknown error occurred. Check the console for more details.";
    }
  }
  

function Trending() {

    const context = useWeb3React();
    const {
      connector,
      library,
      chainId,
      account,
      activate,
      deactivate,
      active,
      error
    } = context;
  
    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = React.useState();
    React.useEffect(() => {
      console.log('running')
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);
  
    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();
  
    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);
  
    // set up block listener
    const [blockNumber, setBlockNumber] = React.useState();
    React.useEffect(() => {
      console.log('running')
      if (library) {
        let stale = false;
  
        console.log('fetching block number!!')
        library
          .getBlockNumber()
          .then(blockNumber => {
            if (!stale) {
              setBlockNumber(blockNumber);
            }
          })
          .catch(() => {
            if (!stale) {
              setBlockNumber(null);
            }
          });
  
        const updateBlockNumber = blockNumber => {
          setBlockNumber(blockNumber);
        };
        library.on("block", updateBlockNumber);
  
        return () => {
          library.removeListener("block", updateBlockNumber);
          stale = true;
          setBlockNumber(undefined);
        };
      }
    }, [library, chainId]);
  
    // fetch eth balance of the connected account
    const [ethBalance, setEthBalance] = React.useState();
    React.useEffect(() => {
      if (library && account) {
        let stale = false;
  
        library
          .getBalance(account)
          .then(balance => {
            if (!stale) {
              setEthBalance(balance);
            }
          })
          .catch(() => {
            if (!stale) {
              setEthBalance(null);
            }
          });
  
        return () => {
          stale = true;
          setEthBalance(undefined);
        };
      }
    }, [library, account, chainId]);
  
    
  const [trending, setTrending] = React.useState([])
  const [topdonated, setTopDonated] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(async() => {
    if(account){
        let x = await axios.get(`https://dry-plateau-94598.herokuapp.com/v1/sales/${account}`)
        let things = [] // trending campaigns
        // x.data[i]
        console.log(x.data, x.data.length)
        for(let i=0; i<x.data.length; i++){
          things.push(
            <div class="col-12 col-md-4 col-lg-4">
      <div class="card" style={{widht:'100%', maxWidth:'391px',height:'450px', boxShadow:'0px 4px 50px rgba(0, 0, 0, 0.05), rgb(206 222 252) -20px 20px 5px', borderRadius:'0px', margin:'0 auto', marginBottom:'6%'}}>
          <img src={x.data[i].image} style={{width:'100%', maxWidth:'343px', height:'246px', maxHeight:'246px', margin:'0 auto', display:'block', marginTop:'5px', objectFit:'cover'}} class="card-img-top" alt="..." />
          <div class="card-body">
            <p class="card-text">{x.data[i].title}</p>
            <h6 style={{color:'blue'}}>Raising ${x.data[i].amount} USD </h6>
          </div>
          <div class="card-footer" style={{background:'#005AFF', borderRadius:'5px'}}>
              <a href={`/fundraise/${x.data[i].address}`}>
            <button className="btn btn-text" style={{width:'100%', color:'white'}}>Visit Fundraiser</button>
            </a>
          </div>
        </div>
      </div>
          )
          }
    
        if(x.data.length === 0){
            things.push(<div className="col-12">
                <h4>No campaigns created</h4>
            </div>)
        }
        setTrending(things)
        setLoading(false)
    }


  }, [account])

  return (
   <div>

    <div class="container-fluid" style={{background:'#005AFF'}}>
    <nav class="navbar navbar-expand-lg" style={{background:'transparent'}}>
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={Logo} width="100px" className="d-inline d-sm-none"  alt="logo" />
            <img src={Logo} width="156px" className="d-none d-md-inline"  alt="logo" />
            </a>
            
            <div>
              <a href="/create">
              <button class="btn btn-outline-light d-none d-lg-inline" type="button" style={{marginRight:'5px'}}>Start a campaign</button>
              <img src="/add.png" width="23px" alt="add" className="d-inline d-sm-none" />
              </a>
              {active ? <button class="btn btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">              {truncateEthAddress(account)}
</button> :                      <button class="btn btn-light" type="button" style={{marginRight:'5px', marginLeft:'5px'}} data-bs-toggle="modal" data-bs-target="#exampleModal"><img src="/thing.png" width="23px" alt="add" /> Connect</button>
      }
                {/* <button class="btn btn-light" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'38px'}}><img src={misc} width="14px" /> Connect</button> */}
<a href="https://token.gocryptome.io/" target="_blank" rel="noreferrer">
              <button class="btn btn-text" type="button" style={{marginRight:'5px', color:'green', fontWeight:'bold'}}><img src={orgtoken} width="30px" /> </button>
              </a>
            </div>
            
        </div>
      </nav>



    </div>

    <div style={{background:'white', minHeight:'1000px'}}>
    <div className="container" style={{backgorund:'transparent'}}>
        <br /> <br />
        <img src={bluebar} style={{ width:'100%', maxWidth:'64px'}} />
        <h2 style={{fontWeight:'bold', marginBottom:'4%'}}>My Created Campaigns</h2>

        <div class="row">
            {account ? <div>           {loading ?                 <img src="/gif.gif" alt="this slowpoke moves"  style={{width:'25%', margin:'0 auto', display:'block'}} /> : trending} </div> : <>
                <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Connect</button>
            </>}
        </div>
    </div>
    </div>

    <br /> <br /> 


    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect Wallet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {active ? <div>
                <p>Connected as {account}</p>
                <button className="btn btn-danger" style={{fontWeight:'bold', fontSize:'12px', width:"100%"}} onClick={() => {
              deactivate();
            }}>
                    Disconnect
                  </button>
                </div> : <div className="row">
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["Injected"]);
              activate(connectorsByName["Injected"]);
               }} >
                  <img src={Metamask} alt="metamask" width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
                <div className="col" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", cursor:'pointer'}} onClick={() => {
              setActivatingConnector(connectorsByName["WalletConnect"]);
              activate(connectorsByName["WalletConnect"]);
               }}>
                  <img src={Wconnect} alt="metamask"  width="100%" style={{maxWidth:'150px', margin:'0 auto', display:'block'}} />
                </div>
              </div>}
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>



      <footer style={{background:'#005AFF', color:'white', height:'181px'}}>
      <div className="container">
            <br /> <br /><br />
            <div className="row">
                <div className="col-12 col-md-6">
                    <p style={{textAlign:'center'}}>© 2010-2022    GoCryptoMe
Terms   Privacy   Legal</p>
                </div>
                <div className="col-12 col-md-6">
                <div className="row">
                        <div className="col-2 col-md-4" />
                        <div className="col-2 col-md-1">
                        <a href="https://twitter.com/gocryptomecoin" target="_blank" rel="noreferrer">
                        <img src={twitter} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://t.me/GoCryptoMe" target="_blank" rel="noreferrer">
                        <img src={telegram} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://go-crypto-me.medium.com/">
                        <img src={medium} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                        <div className="col-2 col-md-1">
                        <a href="https://www.reddit.com/r/GoCryptoMeCoin/">
                        <img src={reddit} width="100%" style={{maxWidth:'43px'}} />
                        </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </footer>  
   </div>

  ); 
}

export default Trending;